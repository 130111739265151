const LineOrLine = () => {
    const containerStyle = {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      margin: '20px 0',
    };
  
    const lineStyle = {
      flex: 1,
      borderBottom: '2px solid rgb(82, 82, 82)',
      margin: '0 10px',
      width:"200px"
    };
  
    const textStyle = {
      fontSize: '14px',
      color: '#666',
    };
  
    return (
      <div style={containerStyle}>
        <span style={lineStyle}></span>
        <span style={textStyle}>OR</span>
        <span style={lineStyle}></span>
      </div>
    );
  };
  
  export default LineOrLine;
  