import React, { useState, useEffect } from "react";
import { useDropzone } from 'react-dropzone';
import { apiUrls } from "../utils/apiUrls";
import { callAPI } from "../utils/apiUtils";
import LineOrLine from "./LineOrLine";

const DropFile = ({ onFileUpload, onComplete, closeModal, activeNavItem, textInput, onError, editAction, editFileId, isImported, originalFileId}) => {

    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const [showDropZone, setShowDropZone] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");

    const [isRecording, setIsRecording] = useState(false);
    const [recordedAudio, setRecordedAudio] = useState(null);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [timer, setTimer] = useState(null);
    const [showRecordingPane, setShowRecordingPane] = useState(false);
    const [svgHover, setSvgHover] = useState(false);
    const [stopSvgHover, setStopSvgHover] = useState(false);
    
    
    const [recordingTime, setRecordingTime] = useState(0); // Timer in seconds

    const handleDivClick = () => {
      setShowDropZone(true); // Set state to show drop zone when the specific div is clicked
    };

    const handleShowRecording = () => {
      setShowRecordingPane(true)
    }
      const handleStartRecording = async () => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          const recorder = new MediaRecorder(stream);
          const audioChunks = [];
    
          recorder.ondataavailable = (event) => {
            audioChunks.push(event.data);
          };
    
          recorder.onstop = () => {
            const audioBlob = new Blob(audioChunks, { type: 'audio/mp3' });
            setRecordedAudio(audioBlob);
          };
    
          recorder.start();
          setMediaRecorder(recorder);
          setIsRecording(true);

      //           // Reset animation and timer
      
      setRecordingTime(0);

     // Start the timer
    const intervalId = setInterval(() => {
      setRecordingTime((prev) => {
        if (prev >= 120) {
          console.log("Timeout reached: Stopping recording.");
          clearInterval(intervalId); // Stop timer at 2 minutes
          recorder.stop(); // Stop the MediaRecorder
          setIsRecording(false); // Stop recording state
          return prev; // No further increment
        }
        return prev + 1; // Increment recording time
      });

    //  setRecordingAnimation((prev) => prev + '|'); // Update animation
    }, 1000);
      
    setTimer(intervalId); 
          // Automatically stop recording after 2 minutes (120,000 ms)
          // const recordingTimer = setTimeout(() => {
          //   console.log("Timeout reached: Stopping recording.");
          //   recorder.stop(); 
          //   setIsRecording(false);
          //   // setRecordingAnimation(""); // Reset animation
          //   // setRecordingTime(0); // 
          //   // clearInterval(interval); // Clear interval
          // }, 120000);
          // setTimer(recordingTimer);
        } catch (err) {
          setErrorMessage('Microphone access is required to record audio.');
        }
      };
    
      const handleStopRecording = () => {
        console.log("handleStopRecording")
        if (mediaRecorder) {
          mediaRecorder.stop();
          setIsRecording(false);
          if (timer) {
            console.log("Timer Stopped")
            clearInterval(timer); // Clear the timer if it exists
          }
        }
         setRecordingTime(0); // Reset timer
         
    };
    const handleFileDrop = async (acceptedFiles) => {
        const file = acceptedFiles[0];
        setSelectedFile(file);
    
        if (activeNavItem !== 'Presenters') {
          // Automatically upload the file if activeNavItem is not 'Presenters'
          try {
            if(editAction === 'imageUpload' ) {
              handleImageAutoSubmit(acceptedFiles)
            } else  { 
              handleFileAutoSubmit(acceptedFiles)
            }
            console.log('File uploaded successfully!');
          } catch (error) {
            console.error('Error occurred while uploading the file:', error);
            // Handle error
          }
        }
      };
    
      const handleUploadRecordedAudio = () => {
        if (recordedAudio) {
          const formData = new FormData();
          formData.append('file', recordedAudio, 'recorded_audio.mp3');
    
          // Send `formData` to the backend using an API call
          fetch('/api/upload-audio', {
            method: 'POST',
            body: formData,
          })
            .then((response) => {
              if (response.ok) {
                alert('Audio uploaded successfully!');
              } else {
                setErrorMessage('Failed to upload audio.');
              }
            })
            .catch(() => {
              setErrorMessage('Error uploading audio.');
            });
        }
      };
      
      const handleReset = () => {
//        textInput = ""
        setSelectedFile(null); // Reset uploaded file
        setRecordedAudio(null); // Reset recorded audio
        setErrorMessage(''); // Clear any error messages
        setIsRecording(false); // Reset recording status
        if (mediaRecorder) {
          mediaRecorder.stop(); // Stop recording if it's still active
          setMediaRecorder(null); // Clear mediaRecorder
        }
        if (timer) {
          clearTimeout(timer); // Clear timer if active
          setTimer(null); // Reset timer
        }
        console.log('All states reset!');
      };

      const handleSubmit = async () => {
        if (!textInput || !textInput.trim()) {
          onError('Please enter presenter name.');
        } else {
        if (selectedFile  || recordedAudio ) {
          try {
            const formData = new FormData();
            let userId=localStorage.getItem("userId")
            //formdata.append("file", files[0].file);
            formData.append("userId", userId);
            console.log("textInput",textInput)
            formData.append("presenterName", textInput);
            if (selectedFile) {
              formData.append("file", selectedFile);
            } else if (recordedAudio) {
              formData.append("file", recordedAudio, "recordedAudio.mp3"); // Add recorded audio
            }

            
              const  headers={
                "Content-Type": "multipart/form-data",
              }
            
              onFileUpload();
              const response = await callAPI(apiUrls.SAMPLEUPLOAD, {}, "POST", formData,{},headers);
              console.log(response)
              if (response.status == 200) {
                onComplete(response.data.id, textInput); 
                console.log('File uploaded successfully!');
              } else {
                console.error('File upload failed.');
                // Handle failure
              }
              
          } catch (error) {
            console.error('Error occurred while uploading the file:', error);
            // Handle error
          }
        } else {
          setErrorMessage('Please upload audio sample.');
        }
        }
      };

    const handleFileAutoSubmit = async (acceptedFiles) => {
      const allowedFileTypes = ['application/pdf', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'];
      if (acceptedFiles.length > 0 && allowedFileTypes.includes(acceptedFiles[0].type)) {
      const formData = new FormData();
      let userId=localStorage.getItem("userId")
      //formdata.append("file", files[0].file);
      formData.append("userId", userId);
      if(isImported === true ) {
        formData.append("isImported", 1);
        formData.append("original_fileId", originalFileId);

      }
      formData.append('file', acceptedFiles[0]);
            let  headers={
          "Content-Type": "multipart/form-data",
        }
      
  
      try {
        onFileUpload();
        const response = await callAPI(apiUrls.CONVERT, {}, "POST", formData,{},headers);
        console.log(response)
        if (response.status == 200) {
          onComplete(response.data.id,response.data.title, false, response.data.deckId); 
          console.log('File uploaded successfully!');
           // Update state to indicate successful file upload
         // onFileUpload(); // Notify parent component about successful upload
          // Handle success
        //  onUploadComplete();
        } else {
          console.error('File upload failed.');
          // Handle failure
        }
      } catch (error) {
        console.error('Error occurred while uploading the file:', error);
        // Handle error
      }
    }else {
      console.error('Invalid file type. Please upload a .ppt, .pptx, or .pdf file.');
      onError('Invalid file type. Please upload a .ppt, .pptx, or .pdf file.');
      // Handle invalid file type
    }
    };
  
    const handleImageAutoSubmit = async (acceptedFiles) => {
    const allowedMediaTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml', 'video/mp4', 'video/mpeg', 'video/quicktime', 'video/x-ms-wmv'/* Add more image MIME types as needed */];

// Array for video MIME types
//const allowedVideoTypes = [, /* Add more video MIME types as needed */];

      if (acceptedFiles.length > 0 && allowedMediaTypes.includes(acceptedFiles[0].type)) {
      const formData = new FormData();
      let userId=localStorage.getItem("userId")
      //formdata.append("file", files[0].file);
      formData.append("userId", userId);
      formData.append("slideId", editFileId);
      
  
      formData.append('file', acceptedFiles[0]);
            let  headers={
          "Content-Type": "multipart/form-data",
        }
      
  
      try {
        onFileUpload();
        const response = await callAPI(apiUrls.MEDIAUPLOAD, {}, "POST", formData,{},headers);
        console.log(response)
        if (response.status == 200) {
          onComplete(response.data.id,response.data.title); 
          console.log('File uploaded successfully!');
           // Update state to indicate successful file upload
         // onFileUpload(); // Notify parent component about successful upload
          // Handle success
        //  onUploadComplete();
        } else {
          console.error('File upload failed.');
          // Handle failure
        }
      } catch (error) {
        console.error('Error occurred while uploading the file:', error);
        // Handle error
      }
    }else {
      console.error('Invalid file type. Please upload a .ppt, .pptx, or .pdf file.');
      onError('Invalid file type. Please upload a .ppt, .pptx, or .pdf file.');
      // Handle invalid file type
    }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: handleFileDrop });
    const label1 = editAction === "imageUpload"?"Images or Videos files supported. Max size: 20 MB":"PPT or PDF files supported. Max size: 15 MB"
    // Format timer as MM:SS
    const formatTime = (seconds) => {
      const minutes = Math.floor(seconds / 60);
      const secs = seconds % 60;
      const formattedSecs = secs < 10 ? `0${secs}` : secs;
      return `${minutes}:${formattedSecs}`;
    };
  return (
<div>
          
        {  activeNavItem === 'Presenters' ? (
          <>
         
   
            {/* <div class="uploadFile" onClick={handleDivClick}> */}
         

            <div class="uploadFile" style={{padding:"30px"}} >
            { showRecordingPane ? (
               <div  class="uploadFile" style={{textAlign:"center",width:"100%", background:"white", height:"10px"}}>
                        <div class="flex mt-12 actionButton">
               {isRecording ? (
                   <div style={{width:"100%"}}>
                 <a  onMouseEnter={() => setStopSvgHover(true)}  onMouseLeave={() => setStopSvgHover(false)} href="javascript:void(0);"  onClick={handleStopRecording}>
                 { stopSvgHover  ? (
                  <svg height="80px" width="80px" fill= "#af1404" id="Layer_1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 97.4 97.4">
                    <rect class="st0" x="37.6" y="37.6" width="22.2" height="22.2" rx="1.7" ry="1.7"/>
                  <path class="st0" d="M48.7,10.6c-21,0-38.1,17-38.1,38.1s17,38.1,38.1,38.1,38.1-17,38.1-38.1S69.7,10.6,48.7,10.6ZM62.8,58.1c0,2.6-2.1,4.7-4.7,4.7h-18.8c-2.6,0-4.7-2.1-4.7-4.7v-18.8c0-2.6,2.1-4.7,4.7-4.7h18.8c2.6,0,4.7,2.1,4.7,4.7v18.8Z"/>
                </svg>
                 ) : (
                  <img  height="80px" src="../assets/images/voiceSpectrum_v2_1.gif" />
                 )}
                 
                 
                </a>
                  <div>
                  {/* Put animation here */}
                  <div style={{ fontSize: '16px' }}>
                    Recording Time: {formatTime(recordingTime)} / 2:00
                  </div>
                </div>
                </div>
                ) : (
                  <>
                  {recordedAudio ? (
                    <div className="recordedAudioPreview" style={{width:"100%"}}>
                      <audio controls src={URL.createObjectURL(recordedAudio)}></audio>
                      <div class="flex mt-12 actionButton" style={{justifyContent:"center"}}>
                         <button class="btn InversePrimary" onClick={handleReset}> Retry</button>
                         <button class="btn InversePrimary" onClick={handleSubmit}>Create Voice Presenter</button>
                      </div>
                 
                    </div>
                  ) : (
                    <a  onMouseEnter={() => setSvgHover(true)}  onMouseLeave={() => setSvgHover(false)}   href="javascript:void(0);"  onClick={handleStartRecording}>
                    <svg  fill = {svgHover?" #af1404":"#181110"} height="80px" width="80px" id="Layer_1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 39.2 39.2">
                      <path class="st0" d="M19.6,21.3c.9,0,1.7-.7,1.7-1.7v-5.8c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7,1.7v5.8c0,.9.7,1.7,1.7,1.7Z"/>
                      <path class="st0" d="M19.6,1.2C9.4,1.2,1.2,9.4,1.2,19.6s8.2,18.4,18.4,18.4,18.4-8.2,18.4-18.4S29.8,1.2,19.6,1.2ZM16.3,13.8c0-1.8,1.5-3.3,3.3-3.3s3.3,1.5,3.3,3.3v5.8c0,1.8-1.5,3.3-3.3,3.3s-3.3-1.5-3.3-3.3v-5.8ZM26.3,19.6c0,3.4-2.5,6.2-5.8,6.6v.9h2.5c.5,0,.8.4.8.8s-.4.8-.8.8h-6.7c-.5,0-.8-.4-.8-.8s.4-.8.8-.8h2.5v-.9c-3.3-.4-5.8-3.2-5.8-6.6v-1.7c0-.5.4-.8.8-.8s.8.4.8.8v1.7c0,2.8,2.2,5,5,5s5-2.2,5-5v-1.7c0-.5.4-.8.8-.8s.8.4.8.8v1.7Z"/>
                      <title>Start Recording</title>
                    </svg>
                    </a>
                  )}
                  </>
                )}
               </div>

             </div> 
           

            ) : (
              <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div  class="uploadFile" style={{textAlign:"center", padding:"30px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                  <path d="M16 3V6.33333M16 24V29.3333M7.66667 16H3M28.3333 16H26.3333M24.6095 24.6095L23.6667 23.6667M24.8856 7.22105L23 9.10667M6.5621 25.4379L10.3333 21.6667M6.83824 6.94491L9.66667 9.77333" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M16 12L12 8M12 8L8 12M12 8V16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#525252" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
                <p class="hoverTooltip">Upload a 2 to 5-minute audio sample to create a realistic voice presenter.</p>
                <span>MP3, M4A, AAC or WAV files supported. Max size: 15 MB</span>
              </div> 
            </div>
            )}
               {selectedFile ? (
                    <div class="voiceTxt mt-32 mb-32">
                      <div class="vIcon mr-8">
                         <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                           <path d="M9 14V4.69166C9 4.04875 9 3.72729 9.13541 3.53369C9.25365 3.36464 9.4363 3.25175 9.64038 3.2216C9.8741 3.18707 10.1616 3.33083 10.7367 3.61835L13.5 5.00002M9 14C9 15.2427 7.99264 16.25 6.75 16.25C5.50736 16.25 4.5 15.2427 4.5 14C4.5 12.7574 5.50736 11.75 6.75 11.75C7.99264 11.75 9 12.7574 9 14Z" stroke="#424242" stroke-linecap="round" stroke-linejoin="round"/>
                         </svg>
                      </div>
                      <div> {selectedFile.name}</div>
                      <a href="javascript:void(0);" class="ml-12">Remove</a>
                   </div>
            ) : (
              <p></p>
            )}
              { !showRecordingPane ? (
              <>
               <LineOrLine />
               <div class="flex mt-12 actionButton">
               
                  <button class="btn InversePrimary" onClick={handleShowRecording}> Record Audio</button>
               
               </div>
               </>
               ) :(
                <>
              <div> Use the script below to record your voice to create a presenter. Make sure you’re in a quiet room—longer samples mean better quality. Click the mic when you’re ready.</div>
                 <div  class="uploadFile" style={{overflowY:"auto", height:"180px", padding:"10px",display:"block", background:"white", marginTop:"20px", marginBottom:"0px"}}>
                
                <p>In a quiet village surrounded by tall mountains, there lived a clockmaker named Sam. He wasn't like other clockmakers. His clocks weren't just for telling time. They could play soft tunes, share old stories, and sometimes even show glimpses of the future.</p>

                <p>One crisp morning, while Sam worked in his little workshop, a black raven flew in through the open window. It landed on a shelf filled with clocks and stared at Sam. In its beak, the bird held a small, golden key. The key was beautiful, with strange patterns carved into it.</p>

                <p>Sam gently took the key, and the raven let out a single, loud caw before flying away. Sam looked around his workshop and spotted an old, unfinished clock he had almost forgotten about. At the center of the clock was a small keyhole. Slowly, he inserted the golden key.</p>

                <p>As soon as he turned it, the clock began to glow. The hands spun faster and faster until everything around Sam changed. His workshop disappeared, and he found himself standing in a bright, golden field. In front of him stood a huge tree, its branches filled with clocks of all shapes and sizes. Each clock ticked softly, their sounds blending like music.</p>

                <p>Then, a gentle voice spoke from the air. “Sam, you have been chosen to guard time itself. Will you accept this great responsibility?”</p>

                <p>Sam thought about it for a moment. It sounded like a lot of work, but he knew it was important. “Yes,” he said firmly.</p>

                <p>The clocks on the tree all chimed together, their sound filling the air. In an instant, Sam was back in his workshop. But things were different now. His clocks seemed brighter, almost alive. From that day on, people came to him not just to repair broken clocks but to help them find lost time and precious moments.</p>

                <p>As for the raven, it returned every evening, sitting silently in his workshop. Sam never knew where it had come from, but he was glad it chose him.</p>

                <p>And so, Sam became more than a clockmaker—he became the Keeper of Time, helping his village hold onto the moments that mattered most.</p>
                
              </div> 
            
              </>

               )}
            </div>
           
  

             {/* Audio Recorder Section */}
        {/* <div className="audioRecorder">
          {isRecording ? (
            <button className="btn stopRecording" onClick={handleStopRecording}>
              Stop Recording
            </button>
          ) : (
            <button className="btn startRecording" onClick={handleStartRecording}>
              Record Audio
            </button>
          )}
        </div> */}

        {/* Playback & Upload Recorded Audio */}
     
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
           { !showRecordingPane && 
            <div class="flex mt-12 actionButton">
                  
                  <button class="btn InversePrimary" onClick={handleSubmit}>Create Voice Presenter</button>
               </div>
            }   
           </> 

        ) : (
          <div {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here...</p>
          ) : (
            <p>Drag 'n' drop some files here, or click to select files</p>
          )}
            <div class="uploadFile" onClick={handleDivClick}>
             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M16 12L12 8M12 8L8 12M12 8V16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#525252" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
             </svg>
             <p class="hoverTooltip">Drag and drop your file here </p>
             <span>{label1}</span>
             </div>
             </div>

        )}      

          
          </div>
  );
};

export default DropFile;
