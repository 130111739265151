// import axios from 'axios';
import axios, * as others from 'axios';
import Qs from 'qs';
import {defaultConfig} from '../config';

const serializerConfig = {
    arrayFormat: 'brackets',
    encode: false,
};


const refreshAccessToken = async () => {
try {
    const API_ROOT = defaultConfig.baseAPIUrl;  
    const refreshToken = localStorage.getItem("refreshToken");
    const headers = {
        Accept: 'application/json',
      };
      
    const userId = localStorage.getItem('userId');
    const response = await axios({
    method: 'post',
    url: `${API_ROOT}/refreshToken`,
    data:  { refreshToken: refreshToken, userId:userId }, // you are sending body instead
    headers: {
     // 'Authorization': `bearer ${token}`,
    'Content-Type': 'application/json'
    }, 
  })
   //   const response = await axios.post(`${API_ROOT}/efreshToken`, {}, { refreshToken: refreshToken });
      if (response.data && response.data.accessToken) {
        // Store the new access token in memory or sessionStorage
        localStorage.setItem("accessToken", response.data.accessToken);
        localStorage.setItem("idToken", response.data.IdToken)
        return response.data.accessToken;
      } else {
        return false
      }
    } catch (error) {
      console.error("Failed to refresh access token:", error);
      throw error;
    }
  };

async function callAPI(path, params, method, data = null, options = {}, headersObj = {}){
    const API_ROOT = defaultConfig.baseAPIUrl;
    const url = API_ROOT + path;
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem("accessToken");
    const headers = {
        Accept: 'application/json',
        ...(token ? { Authorization: `Bearer ${token}` } : {}), 
        ...headersObj,
        'x-user-id': userId
    };

    
    // If userId exists, include it in the query parameters
    if (userId !== undefined && userId !== null && userId !== '') {
    //    params = { ...params, globalUserId: userId };
    }
    // Add a random parameter to prevent caching
    params = { ...params, _cacheBuster: new Date().getTime() }; // or use Math.random()

    const sessionId = localStorage.getItem('sessionId');
    // If userId exists, include it in the query parameters
    if (sessionId !== undefined && sessionId !== null && sessionId !== '') {
        params = { ...params, sessionId: sessionId };
    }

    // return axios({
    //     method,
    //     url,
    //     params,
    //     paramsSerializer: (paramObject) => Qs.stringify(paramObject, serializerConfig),
    //     data,
    //     headers,
    //     ...options,
    // });

    try {
        const response = await axios({
          method,
          url,
          params,
          paramsSerializer: (paramObject) => Qs.stringify(paramObject),
          data,
          headers,
          ...options,
        });
        return response; // Successful response
      } catch (error) {
        console.log(error)
        if (error.response && error.response.status === 401) {
          try {
            // Refresh the access token
            const newToken = await refreshAccessToken();
            if (newToken) {
              headers.Authorization = `Bearer ${newToken}`;
              // Retry the original request with the new token
              const retryResponse = await axios({
                method,
                url,
                params,
                paramsSerializer: (paramObject) => Qs.stringify(paramObject),
                data,
                headers,
                ...options,
              });
              return retryResponse; // Return successful retry response
            }
          } catch (refreshError) {
            console.error("Failed to refresh token or retry request:", refreshError);
            // Logout the user if refresh fails
            handleLogout();
          }
        } else {
          throw error; // Re-throw other errors
        }
      }

      const handleLogout = () => {
        // Clear tokens and user data
     //   sessionStorage.clear();
        // Redirect to login page
        window.location.href = "/login";
      };

}



export {callAPI, refreshAccessToken};