import React, { useState, useEffect, useRef } from 'react';
import { apiUrls } from "../utils/apiUrls";
import { callAPI } from "../utils/apiUtils";
import BarChart from './BarChart.js';

const Analytics = ({analyticsItemId, analyticsTitle, analyticsImage, setActiveNavItem }) => {
   const backButtonRef = useRef(null);
   const [isFiltered, setIsFiltered] = useState(false);  // Track if filter is applied
   const [isFetching, setIsFetching] = useState(false);
   const [totalViews, setTotalViews] = useState('');
   const [fetchToggle, setFetchToggle] = useState(false)
   const [activeTab, setActiveTab] = useState('views')
   const [totalUniqueView, setTotalUniqueView] = useState('');
   const [emailResult, setEmailResult] = useState([]);
   const [maxTsCountIndex, setMaxTsCountIndex] = useState([]);
   const [maxTsCount, setMaxTsCount] = useState([]);
   const [averageTimespent, setAverageTimespent] = useState([]);
   const [slideCount, setSlidecount] = useState([]);
   const [maxTsSideimage, setMaxTsSideimage] = useState([]);
   const [slideData, setSlideData] = useState([]);
   const [filEmail, setFilEmail] = useState("");
   const [period, setPeriod] = useState(7);
   
   const [chartData, setChartData] = useState({
      labels: [],
      datasets: [
        {
          label: 'Slide TIme',
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(75, 192, 192, 0.4)',
          hoverBorderColor: 'rgba(75, 192, 192, 1)',
          data: [65, 59, 80, 81, 56, 55, 40],
        },
      ],
    });
 
    const [chartOptions, setChartOptions] = useState({
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    });

    const filterEmail  = async (femail = false,  period = false) => {
       if (analyticsItemId) {
           try {
            backButtonRef.current.scrollIntoView({ behavior: "smooth" });
             setIsFiltered(true);
             setFilEmail(femail)
             setPeriod(period)
            //  const response = await callAPI(apiUrls.GETANALYTICSDATA, { pid: analyticsItemId, fEm:femail }, 'GET');
            //  const slideData = response.data.slides
            //  setSlideData(slideData)
            //  setSlidecount(response.data.slides.length)
            //  const labelIndex = slideData.map((_, index) => index + 1);
            //  const labelimageIds = slideData.map(item => item.imageId);
            //  const timespent = slideData.map(item => item.timespent);
            //  const slideView = slideData.map(item => item.viewCount);
            //  setTotalViews(response.data.decKtotalView)
            //  setTotalUniqueView(response.data.totalUniqueView)
            //  setEmailResult(response.data.emailResult)
            //  setMaxTsCount(response.data.maxTimespentSlideId.maxTimespentCount)
            //  setMaxTsCountIndex(response.data.maxTimespentSlideId.maxTimeSpentIndex)
            //  setMaxTsSideimage(response.data.maxTimespentSlideId.maxTimespentSideimage)
            //  setAverageTimespent(response.data.averageTimespent)
            //  setIsFetching(false);
            //  setChartData({
            //    labels: labelIndex,
            //    datasets: [
            //      {
            //        label: 'View Count',
            //        backgroundColor: 'rgba(75, 192, 192, 0.2)',
            //        borderColor: 'rgba(75, 192, 192, 1)',
            //        borderWidth: 1,
            //        hoverBackgroundColor: 'rgba(75, 192, 192, 0.4)',
            //        hoverBorderColor: 'rgba(75, 192, 192, 1)',
            //        data: slideView,
            //      },
            //    ],
            //  });
             
           } catch (error) {
             console.error('Failed to fetch item details:', error);
           }
         }
      
    }  
    const formatSecTime = (seconds) => {
      const minutes = Math.floor(seconds / 60);
      const secs = seconds % 60;
      return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    } 

    const updateChart = async (endpoint) => {
      const labelIndex = slideData.map((_, index) => `Slide ${index + 1}`);

      let dataValue
      let label
      setActiveTab(endpoint)
      try {
         if(endpoint === 'views') {
            setChartOptions({scales: {
               y: {
                 beginAtZero: true,
               },
             },})
             dataValue = slideData.map(item => item.viewCount);
            label = "View count"
            setChartData({
               labels: labelIndex,
               datasets: [
                 {
                   label: label,
                   backgroundColor: 'rgba(75, 192, 192, 0.2)',
                   borderColor: 'rgba(75, 192, 192, 1)',
                   borderWidth: 1,
                   hoverBackgroundColor: 'rgba(75, 192, 192, 0.4)',
                   hoverBorderColor: 'rgba(75, 192, 192, 1)',
                   data: dataValue,
                 },
               ],
             });
         } else if(endpoint === "timespent") {
            setChartOptions({
               responsive: true,
               plugins: {
                 tooltip: {
                   callbacks: {
                     label: function (tooltipItem) {
                       const averageTime = tooltipItem.raw; // raw value of `dataValue`
                       const slideNumber = tooltipItem.dataIndex + 1; // slide number
                       return `Avg time spent on slide ${formatTime(averageTime)}`;
                     },
                   },
                 },
                 legend: {
                   display: true,
                   position: 'top',
                 },
               },
               scales: {
                 y: {
                   beginAtZero: true,
                 },
               },
             });
             
             const dataValue = slideData.map(item => item.averageTimeSpent); // average time in seconds
             const label = "Time spent";
             
             setChartData({
               labels: labelIndex,
               datasets: [
                 {
                   label: label,
                   backgroundColor: 'rgba(75, 192, 192, 0.2)',
                   borderColor: 'rgba(75, 192, 192, 1)',
                   borderWidth: 1,
                   hoverBackgroundColor: 'rgba(75, 192, 192, 0.4)',
                   hoverBorderColor: 'rgba(75, 192, 192, 1)',
                   data: dataValue,
                 },
               ],
             });
         } else if(endpoint ==="audio") {
            
          //  const labelIndex = ['Slide 1', 'Slide 2', 'Slide 3', 'Slide 4']; // Labels for each slide
               dataValue = slideData.map(item => item.audioData);
               console.log("dataValue",dataValue)
            // Dummy data for slide view counts: 25%, 50%, 75%, 100% view counts for each slide
            // const slideView = [
            //   [10, 20, ], // Slide 1: 25%, 50%, 75%, 100% view counts
            //   [15, 25, 35, 45], // Slide 2
            //   [20, 30, 40], // Slide 3
            //   [25, 35, 45, 55], // Slide 4
            // ];
            setChartData({labels: labelIndex,
               datasets: [
                  {
                    label: '25%',
                    backgroundColor: 'rgba(75, 192, 192, 0.7)', // Slightly more opaque for stacked look
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                    data: dataValue.map(view => view[0]), // 25% counts
                  },
                  {
                    label: '50%',
                    backgroundColor: 'rgba(54, 162, 235, 0.7)',
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 1,
                    data: dataValue.map(view => view[1]), // 50% counts
                  },
                  {
                    label: '75%',
                    backgroundColor: 'rgba(153, 102, 255, 0.7)',
                    borderColor: 'rgba(153, 102, 255, 1)',
                    borderWidth: 1,
                    data: dataValue.map(view => view[2]), // 75% counts
                  },
                  {
                    label: '100%',
                    backgroundColor: 'rgba(255, 159, 64, 0.7)',
                    borderColor: 'rgba(255, 159, 64, 1)',
                    borderWidth: 1,
                    data: dataValue.map(view => view[3]), // 100% counts
                  },
                ],
             })

             setChartOptions({
               responsive: true,
               plugins: {
                  tooltip: {
                     callbacks: {
                       label: function (tooltipItem) {
                         const label = tooltipItem.dataset.label; // e.g., "100%"
                         const value = tooltipItem.raw; // e.g., 1
                         return `${label} of the audio played ${value} times`;
                       },
                     },
                   },
                 legend: {
                   position: 'top',
                 },
                 title: {
                   display: true,
                   text: 'Audio Played per Slide', // Updated title
                 },
               },
               scales: {
                 x: {
                   stacked: true, // Enable stacking on x-axis
                 },
                 y: {
                   stacked: true, // Enable stacking on y-axis
                   beginAtZero: true, // Start y-axis from 0
                 },
               },
             })
         }
  
        // Assuming the API response has `labels` and `values` arrays
   
       } catch (error) {
         console.error('Error fetching data', error);
       }
    };
    useEffect(() => {
      console.log("Anaaaaaaaaaaaaaaaaaaaaaaaaa")
      
         const fetchItemDetail = async () => {
           if (analyticsItemId) {
            setActiveTab("views")
            const reqData = { pid: analyticsItemId}
             try {
               if(filEmail) {
                  setIsFiltered(true);
                  reqData.fEm = filEmail ;
               } else {
                  setIsFiltered(false);
                  setIsFetching(false);
               }
               
               if(period) {
                  reqData.period = period
               }
               
               const response = await callAPI(apiUrls.GETANALYTICSDATA, reqData, 'GET');
               const slideData = response.data.slides
               setSlideData(slideData)
               setSlidecount(response.data.slides.length)
               const labelIndex = slideData.map((_, index) => `Slide ${index + 1}`);
               const labelimageIds = slideData.map(item => item.imageId);
               const timespent = slideData.map(item => item.timespent);
               const slideView = slideData.map(item => item.viewCount);
            //   const slideAudio = slideData.map(item => item.audioData);
             //  console.log(slideAudio);
               setTotalViews(response.data.decKtotalView)
               setTotalUniqueView(response.data.totalUniqueView)
               setEmailResult(response.data.emailResult)
               setMaxTsCount(response.data.maxTimespentSlideId.maxTimespentCount)
               setMaxTsCountIndex(response.data.maxTimespentSlideId.maxTimeSpentIndex)
               setMaxTsSideimage(response.data.maxTimespentSlideId.maxTimespentSideimage)
               setAverageTimespent(response.data.averageTimespent)
               
               setChartData({
                 labels: labelIndex,
                 datasets: [
                   {
                     label: 'View Count',
                     backgroundColor: 'rgba(75, 192, 192, 0.2)',
                     borderColor: 'rgba(75, 192, 192, 1)',
                     borderWidth: 1,
                     hoverBackgroundColor: 'rgba(75, 192, 192, 0.4)',
                     hoverBorderColor: 'rgba(75, 192, 192, 1)',
                     data: slideView,
                   },
                 ],
               });
             } catch (error) {
               console.error('Failed to fetch item details:', error);
             }
           }
         };
     
         fetchItemDetail();
       }, [analyticsItemId,  filEmail, period]);
     
       function formatTime(seconds) {
         // Ensure `seconds` is treated as a number
         seconds = Number(seconds);
       
         if (seconds >= 3600) {
           const hours = Math.floor(seconds / 3600);
           const minutes = Math.floor((seconds % 3600) / 60);
           const secs = (seconds % 60).toFixed(2);
           return `${hours}h ${minutes}m ${secs}s`;
         } else if (seconds >= 60) {
           const minutes = Math.floor(seconds / 60);
           const secs = (seconds % 60).toFixed(2);
           return `${minutes}m ${secs}s`;
         } else {
           return `${seconds.toFixed(2)}s`;
         }
       }
       
       
       const handleBackButtonClick = () => {
         // Reset fetchToggle to trigger `useEffect`
         setIsFiltered(false);
         setIsFetching(true);
         setFilEmail("")
        // setFetchToggle((prev) => !prev);
       };

       

    return (
<div class="dashContent AnalyticsContent">
<div class="pageNavigation mb-24">
   <ul>
      <li onClick={() => setActiveNavItem('Decks')}  ref={backButtonRef}>
         <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 18L9 12L15 6" stroke="#D9D9D9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
         </svg>
         <span>
         <a href="javascript:void(0);" >Go back</a>
         </span>
      </li>
   </ul>
</div>
<div class="AnalyHeader">
   <div class="slideImg">
      <div class="thumb">
         <img src={analyticsImage} alt="" />
      </div>
   </div>
   <div class="headingText">
      <h2>
         <span>{analyticsTitle}</span>
         <a href="javascript:void(0);">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M21 9.00001L21 3.00001M21 3.00001H15M21 3.00001L12 12M10 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V14" stroke="#D9D9D9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
         </a>
      </h2>
      <p class="mt-8">
         <span>{slideCount} slides</span>
         {/* <span>25 April</span> */}
      </p>
   </div>
</div>
<div class="AnalyStat mt-24">
   <section>
      <div>
         <h6>
            <span>{totalViews}</span>
         </h6>
         <p>Total Deck Views</p>
      </div>
   </section>
   <section>
      <div>
         <h6>
            <span>Slide {maxTsCountIndex}</span>
            <a href="javascript:void(0);">
               <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18.1001 7.50001L18.1001 2.50001M18.1001 2.50001H13.1001M18.1001 2.50001L10.6001 10M8.93343 2.5H7.1001C5.69997 2.5 4.9999 2.5 4.46512 2.77248C3.99472 3.01217 3.61226 3.39462 3.37258 3.86502C3.1001 4.3998 3.1001 5.09987 3.1001 6.5V13.5C3.1001 14.9001 3.1001 15.6002 3.37258 16.135C3.61226 16.6054 3.99472 16.9878 4.46512 17.2275C4.9999 17.5 5.69997 17.5 7.1001 17.5H14.1001C15.5002 17.5 16.2003 17.5 16.7351 17.2275C17.2055 16.9878 17.5879 16.6054 17.8276 16.135C18.1001 15.6002 18.1001 14.9001 18.1001 13.5V11.6667" stroke="#D9D9D9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
               </svg>
            </a>
         </h6>
         <p>Most Time Spent</p>
      </div>
      <div class="thumb">
         <img src={maxTsSideimage} alt="" />
      </div>
   </section>
   <section>
      <div>
         <h6>
            <span>{totalUniqueView}</span>
            <a href="javascript:void(0);">
               <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18.1001 7.50001L18.1001 2.50001M18.1001 2.50001H13.1001M18.1001 2.50001L10.6001 10M8.93343 2.5H7.1001C5.69997 2.5 4.9999 2.5 4.46512 2.77248C3.99472 3.01217 3.61226 3.39462 3.37258 3.86502C3.1001 4.3998 3.1001 5.09987 3.1001 6.5V13.5C3.1001 14.9001 3.1001 15.6002 3.37258 16.135C3.61226 16.6054 3.99472 16.9878 4.46512 17.2275C4.9999 17.5 5.69997 17.5 7.1001 17.5H14.1001C15.5002 17.5 16.2003 17.5 16.7351 17.2275C17.2055 16.9878 17.5879 16.6054 17.8276 16.135C18.1001 15.6002 18.1001 14.9001 18.1001 13.5V11.6667" stroke="#D9D9D9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
               </svg>
            </a>
         </h6>
         <p>Total Unique Views</p>
      </div>
      {/* <div class="thumb">
         <img src="../assets/images/Rectangle 22.png" alt="" />
      </div> */}
   </section>
   <section>
      <div>
         <h6>
            <span>{formatTime(averageTimespent)}</span>
         </h6>
         <p>Avg time spent per slide</p>
      </div>
   </section>
   {/* <section>
      <div>
         <h6 class="mb-4">
            <svg width="145" height="24" viewBox="0 0 145 24" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M11.6826 3.45332C11.913 2.98638 12.0283 2.75291 12.1847 2.67831C12.3208 2.61341 12.479 2.61341 12.6151 2.67831C12.7716 2.75291 12.8868 2.98638 13.1173 3.45332L15.304 7.88328C15.372 8.02113 15.406 8.09006 15.4558 8.14358C15.4998 8.19096 15.5526 8.22935 15.6112 8.25662C15.6775 8.28742 15.7535 8.29854 15.9056 8.32077L20.7969 9.03571C21.312 9.11099 21.5695 9.14863 21.6887 9.27444C21.7924 9.38389 21.8412 9.5343 21.8214 9.68377C21.7987 9.85558 21.6123 10.0372 21.2394 10.4004L17.7014 13.8464C17.5911 13.9538 17.5359 14.0076 17.5003 14.0715C17.4688 14.128 17.4486 14.1902 17.4408 14.2545C17.432 14.3271 17.445 14.403 17.471 14.5547L18.3059 19.4221C18.3939 19.9355 18.4379 20.1922 18.3552 20.3445C18.2832 20.477 18.1553 20.57 18.007 20.5975C17.8365 20.6291 17.606 20.5078 17.145 20.2654L12.7723 17.9658C12.636 17.8942 12.5679 17.8584 12.4961 17.8443C12.4326 17.8318 12.3672 17.8318 12.3037 17.8443C12.2319 17.8584 12.1638 17.8942 12.0276 17.9658L7.65483 20.2654C7.19382 20.5078 6.96331 20.6291 6.79287 20.5975C6.64458 20.57 6.51662 20.477 6.44464 20.3445C6.3619 20.1922 6.40593 19.9355 6.49398 19.4221L7.32879 14.5547C7.35481 14.403 7.36783 14.3271 7.35902 14.2545C7.35123 14.1902 7.33101 14.128 7.29951 14.0715C7.26392 14.0076 7.20878 13.9538 7.09849 13.8464L3.56046 10.4004C3.18757 10.0372 3.00112 9.85558 2.97843 9.68377C2.95869 9.5343 3.00746 9.38389 3.11115 9.27444C3.23034 9.14863 3.48787 9.11099 4.00295 9.03571L8.89421 8.32077C9.04633 8.29854 9.12238 8.28742 9.18862 8.25662C9.24727 8.22935 9.30006 8.19096 9.34409 8.14358C9.39381 8.09006 9.42783 8.02113 9.49588 7.88328L11.6826 3.45332Z" fill="#D9D9D9" stroke="#D9D9D9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
               <path d="M41.6826 3.45332C41.913 2.98638 42.0283 2.75291 42.1847 2.67831C42.3208 2.61341 42.479 2.61341 42.6151 2.67831C42.7716 2.75291 42.8868 2.98638 43.1173 3.45332L45.304 7.88328C45.372 8.02113 45.406 8.09006 45.4558 8.14358C45.4998 8.19096 45.5526 8.22935 45.6112 8.25662C45.6775 8.28742 45.7535 8.29854 45.9056 8.32077L50.7969 9.03571C51.312 9.11099 51.5695 9.14863 51.6887 9.27444C51.7924 9.38389 51.8412 9.5343 51.8214 9.68377C51.7987 9.85558 51.6123 10.0372 51.2394 10.4004L47.7014 13.8464C47.5911 13.9538 47.5359 14.0076 47.5003 14.0715C47.4688 14.128 47.4486 14.1902 47.4408 14.2545C47.432 14.3271 47.445 14.403 47.471 14.5547L48.3059 19.4221C48.3939 19.9355 48.4379 20.1922 48.3552 20.3445C48.2832 20.477 48.1553 20.57 48.007 20.5975C47.8365 20.6291 47.606 20.5078 47.145 20.2654L42.7723 17.9658C42.636 17.8942 42.5679 17.8584 42.4961 17.8443C42.4326 17.8318 42.3672 17.8318 42.3037 17.8443C42.2319 17.8584 42.1638 17.8942 42.0276 17.9658L37.6548 20.2654C37.1938 20.5078 36.9633 20.6291 36.7929 20.5975C36.6446 20.57 36.5166 20.477 36.4446 20.3445C36.3619 20.1922 36.4059 19.9355 36.494 19.4221L37.3288 14.5547C37.3548 14.403 37.3678 14.3271 37.359 14.2545C37.3512 14.1902 37.331 14.128 37.2995 14.0715C37.2639 14.0076 37.2088 13.9538 37.0985 13.8464L33.5605 10.4004C33.1876 10.0372 33.0011 9.85558 32.9784 9.68377C32.9587 9.5343 33.0075 9.38389 33.1112 9.27444C33.2303 9.14863 33.4879 9.11099 34.0029 9.03571L38.8942 8.32077C39.0463 8.29854 39.1224 8.28742 39.1886 8.25662C39.2473 8.22935 39.3001 8.19096 39.3441 8.14358C39.3938 8.09006 39.4278 8.02113 39.4959 7.88328L41.6826 3.45332Z" fill="#D9D9D9" stroke="#D9D9D9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
               <path d="M71.6826 3.45332C71.913 2.98638 72.0283 2.75291 72.1847 2.67831C72.3208 2.61341 72.479 2.61341 72.6151 2.67831C72.7716 2.75291 72.8868 2.98638 73.1173 3.45332L75.304 7.88328C75.372 8.02113 75.406 8.09006 75.4558 8.14358C75.4998 8.19096 75.5526 8.22935 75.6112 8.25662C75.6775 8.28742 75.7535 8.29854 75.9056 8.32077L80.7969 9.03571C81.312 9.11099 81.5695 9.14863 81.6887 9.27444C81.7924 9.38389 81.8412 9.5343 81.8214 9.68377C81.7987 9.85558 81.6123 10.0372 81.2394 10.4004L77.7014 13.8464C77.5911 13.9538 77.5359 14.0076 77.5003 14.0715C77.4688 14.128 77.4486 14.1902 77.4408 14.2545C77.432 14.3271 77.445 14.403 77.471 14.5547L78.3059 19.4221C78.3939 19.9355 78.4379 20.1922 78.3552 20.3445C78.2832 20.477 78.1553 20.57 78.007 20.5975C77.8365 20.6291 77.606 20.5078 77.145 20.2654L72.7723 17.9658C72.636 17.8942 72.5679 17.8584 72.4961 17.8443C72.4326 17.8318 72.3672 17.8318 72.3037 17.8443C72.2319 17.8584 72.1638 17.8942 72.0276 17.9658L67.6548 20.2654C67.1938 20.5078 66.9633 20.6291 66.7929 20.5975C66.6446 20.57 66.5166 20.477 66.4446 20.3445C66.3619 20.1922 66.4059 19.9355 66.494 19.4221L67.3288 14.5547C67.3548 14.403 67.3678 14.3271 67.359 14.2545C67.3512 14.1902 67.331 14.128 67.2995 14.0715C67.2639 14.0076 67.2088 13.9538 67.0985 13.8464L63.5605 10.4004C63.1876 10.0372 63.0011 9.85558 62.9784 9.68377C62.9587 9.5343 63.0075 9.38389 63.1112 9.27444C63.2303 9.14863 63.4879 9.11099 64.0029 9.03571L68.8942 8.32077C69.0463 8.29854 69.1224 8.28742 69.1886 8.25662C69.2473 8.22935 69.3001 8.19096 69.3441 8.14358C69.3938 8.09006 69.4278 8.02113 69.4959 7.88328L71.6826 3.45332Z" fill="#D9D9D9" stroke="#D9D9D9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
               <path d="M101.683 3.45332C101.913 2.98638 102.028 2.75291 102.185 2.67831C102.321 2.61341 102.479 2.61341 102.615 2.67831C102.772 2.75291 102.887 2.98638 103.117 3.45332L105.304 7.88328C105.372 8.02113 105.406 8.09006 105.456 8.14358C105.5 8.19096 105.553 8.22935 105.611 8.25662C105.677 8.28742 105.754 8.29854 105.906 8.32077L110.797 9.03571C111.312 9.11099 111.569 9.14863 111.689 9.27444C111.792 9.38389 111.841 9.5343 111.821 9.68377C111.799 9.85558 111.612 10.0372 111.239 10.4004L107.701 13.8464C107.591 13.9538 107.536 14.0076 107.5 14.0715C107.469 14.128 107.449 14.1902 107.441 14.2545C107.432 14.3271 107.445 14.403 107.471 14.5547L108.306 19.4221C108.394 19.9355 108.438 20.1922 108.355 20.3445C108.283 20.477 108.155 20.57 108.007 20.5975C107.837 20.6291 107.606 20.5078 107.145 20.2654L102.772 17.9658C102.636 17.8942 102.568 17.8584 102.496 17.8443C102.433 17.8318 102.367 17.8318 102.304 17.8443C102.232 17.8584 102.164 17.8942 102.028 17.9658L97.6548 20.2654C97.1938 20.5078 96.9633 20.6291 96.7929 20.5975C96.6446 20.57 96.5166 20.477 96.4446 20.3445C96.3619 20.1922 96.4059 19.9355 96.494 19.4221L97.3288 14.5547C97.3548 14.403 97.3678 14.3271 97.359 14.2545C97.3512 14.1902 97.331 14.128 97.2995 14.0715C97.2639 14.0076 97.2088 13.9538 97.0985 13.8464L93.5605 10.4004C93.1876 10.0372 93.0011 9.85558 92.9784 9.68377C92.9587 9.5343 93.0075 9.38389 93.1112 9.27444C93.2303 9.14863 93.4879 9.11099 94.0029 9.03571L98.8942 8.32077C99.0463 8.29854 99.1224 8.28742 99.1886 8.25662C99.2473 8.22935 99.3001 8.19096 99.3441 8.14358C99.3938 8.09006 99.4278 8.02113 99.4959 7.88328L101.683 3.45332Z" fill="#D9D9D9" stroke="#D9D9D9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
               <path d="M131.683 3.45332C131.913 2.98638 132.028 2.75291 132.185 2.67831C132.321 2.61341 132.479 2.61341 132.615 2.67831C132.772 2.75291 132.887 2.98638 133.117 3.45332L135.304 7.88328C135.372 8.02113 135.406 8.09006 135.456 8.14358C135.5 8.19096 135.553 8.22935 135.611 8.25662C135.677 8.28742 135.754 8.29854 135.906 8.32077L140.797 9.03571C141.312 9.11099 141.569 9.14863 141.689 9.27444C141.792 9.38389 141.841 9.5343 141.821 9.68377C141.799 9.85558 141.612 10.0372 141.239 10.4004L137.701 13.8464C137.591 13.9538 137.536 14.0076 137.5 14.0715C137.469 14.128 137.449 14.1902 137.441 14.2545C137.432 14.3271 137.445 14.403 137.471 14.5547L138.306 19.4221C138.394 19.9355 138.438 20.1922 138.355 20.3445C138.283 20.477 138.155 20.57 138.007 20.5975C137.837 20.6291 137.606 20.5078 137.145 20.2654L132.772 17.9658C132.636 17.8942 132.568 17.8584 132.496 17.8443C132.433 17.8318 132.367 17.8318 132.304 17.8443C132.232 17.8584 132.164 17.8942 132.028 17.9658L127.655 20.2654C127.194 20.5078 126.963 20.6291 126.793 20.5975C126.645 20.57 126.517 20.477 126.445 20.3445C126.362 20.1922 126.406 19.9355 126.494 19.4221L127.329 14.5547C127.355 14.403 127.368 14.3271 127.359 14.2545C127.351 14.1902 127.331 14.128 127.3 14.0715C127.264 14.0076 127.209 13.9538 127.098 13.8464L123.56 10.4004C123.188 10.0372 123.001 9.85558 122.978 9.68377C122.959 9.5343 123.007 9.38389 123.111 9.27444C123.23 9.14863 123.488 9.11099 124.003 9.03571L128.894 8.32077C129.046 8.29854 129.122 8.28742 129.189 8.25662C129.247 8.22935 129.3 8.19096 129.344 8.14358C129.394 8.09006 129.428 8.02113 129.496 7.88328L131.683 3.45332Z" stroke="#D9D9D9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
         </h6>
         <p>Ratings</p>
      </div>
   </section> */}
</div>
<div class="AnalyGraphView mt-24">
   <ul style={{borderBottom:"none", marginBottom:"20px"}}>
   
      <li>
   {isFiltered && (
   <button onClick={handleBackButtonClick}>Back</button>
      )}

      {/* Display "Fetching..." or "Overall" */}
      
        {isFetching ? "Fetching..." : !isFiltered && "Slide Stats"}
       
      </li>
      <li className={period === 7 ? 'active' : ''} ><a href="javascript:void(0);" onClick={() =>  filterEmail(filEmail, 7)} >7 days</a></li>
      <li className={period === 30 ? 'active' : ''}><a href="javascript:void(0);" onClick={() =>  filterEmail(filEmail,30)} >30 days</a></li>
      <li className={period === 90 ? 'active' : ''}><a href="javascript:void(0);" onClick={() =>  filterEmail(filEmail,90)} >90 days</a></li>
      
      </ul>
   <ul>
   <li className={activeTab === 'views' ? 'active' : ''}>
         <a href="javascript:void(0);" onClick={() => updateChart('views')}>Total Views</a>
      </li>
      <li className={activeTab === 'timespent' ? 'active' : ''}>
         <a href="javascript:void(0);" onClick={() => updateChart('timespent')} >Avg Time Spent</a>
      </li>
      <li className={activeTab === 'audio' ? 'active' : ''}>
         <a href="javascript:void(0);" onClick={() => updateChart('audio')} >Audio Played</a>
      </li>
      {/* <li>
         <a href="javascript:void(0);">Played Audio</a>
      </li> */}
   </ul>
   <div class="mt-24">
   <BarChart data={chartData} options={chartOptions} />
   </div>
</div>
<div class="AnalyListhView mt-24">
   <h5>VIEWER LIST</h5>
   <input type="text" name="" placeholder="Search emails..." class="w100 mt-24" />
   <ul>
   <li>
         <span><b>Email</b></span>
         <span><b>Last View</b></span>
      </li>
   {emailResult.map((item, index) => (
                  
      <li>
         <span onClick={() => filterEmail(item.pemail, period)} style={{ cursor: "pointer" }}>{item.pemail}</span>
         <span>{item.created}</span>
      </li>
          ))}
   </ul>
</div>
</div>
);
};

export default Analytics;