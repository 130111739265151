import React, { useEffect, useRef, useState } from 'react';

import Reveal from 'reveal.js';
import PresenatationHeader from '../components/PresenatationHeader'; 
import Modal from '../components/Modal';
import addTrackData from '../components/TrackingService';
import { constants } from "../utils/constants";
let slideId;
let moveToNextSlideTimeout = null;
let isPaused = false;
const Presentation = ({PresentationData, onBack, itemId, prevIndexNo, isSharedPrsenation, selectedTitle, userDetail, deckId}) => {
  const presentationHeaderRef = useRef(null);
  const audioRef = useRef(null);
  let timeouts = [];
  
  
  let isManualNavigation = false;
  console.log("itemId=================",itemId)
  
  const [showPlayIcon, setShowPlayIcon] = useState(true);
  const [isHoverTransitioned, setIsHoverTransitioned] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [showAlert, setShowAlert] = useState(true);
  const [firstTtimePlay, setFirstTtimePlay] = useState(true);
  
  const [userEmail, setUserEmail] = useState('');
  const milestonesRef = useRef({ 25: false, 50: false, 75: false, 100: false });
  const hasReached100Percent = useRef(false);
  const debounceTimer = useRef(null);
  const currentSlideIndexRef = useRef(null);
  let slideStartTime = useRef(Date.now());
  const [isFullScreen, setIsFullScreen] = useState(isSharedPrsenation?false:true);
  const [isAutoPlay, setIsAutoPlay] = useState(false);
  const [slideNum, setSlideNum] = useState(0);
  const [isPillVisible, setIsPillVisible] = useState(false);
  const [isUserPlanExists, setIsUserPlanExists] = useState(userDetail.isPlanExists);
  
  
  let piletimeoutId;

  const handleMouseMove = () => {
    // Show the div when the mouse moves
    setIsPillVisible(true);
    setIsVisible(true)
    // Clear the previous timeout
    if (piletimeoutId) {
      clearTimeout(piletimeoutId);
    }

    // Hide the div after 3 seconds of inactivity
    piletimeoutId = setTimeout(() => {
      setIsPillVisible(false);
      setIsVisible(false)
    }, 3000);
  };
  const disableArrowNavigation = () => {
    // Disable arrow navigation in Reveal.js
    Reveal.configure({ keyboard: false, controls: false });
  };

  const enableArrowNavigation = () => {
    // Re-enable navigation
    Reveal.configure({ keyboard: true, controls: false });
  };
  useEffect(() => {
 
    // Add the event listener for mouse movement
    window.addEventListener('mousemove', handleMouseMove);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      if (piletimeoutId) clearTimeout(piletimeoutId);
    };
  }, []);
  

  useEffect(() => {
    if(isSharedPrsenation) {
      const storedEmail = localStorage.getItem('userPresEmail');
      const emailExpiry = localStorage.getItem('emailExpiry');
      const currentTime = Date.now();
  
      if (storedEmail && emailExpiry && currentTime < parseInt(emailExpiry)) {
        // Email is not expired, set it in state
        setUserEmail(storedEmail);
        setShowAlert(false);
       // addTrackData({ en: 'ped', ev:storedEmail,  ot:"pid", ov:itemId}); 
       if (itemId && firstTtimePlay === false) {
          slideStartTime.current = Date.now();
          addTrackData({ en: constants.T_P_EM, ev:storedEmail,  did:itemId}); 
       }
      } else {
        // Email is expired or not present, clear it from local storage and state
        localStorage.removeItem('userPresEmail');
        localStorage.removeItem('emailExpiry');
      }
 
  } else {
    setShowAlert(false);
   // setIsFullScreen(true)
  }
  }, [itemId, firstTtimePlay]);

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    console.log("upeeeeeeeeeeeeee",userDetail.isPlanExists)
    setIsUserPlanExists(userDetail.isPlanExists)
    const initializeReveal = async () => {
    if (PresentationData) {
//console.log("PresentationData", PresentationData)
       // Track image loading state
       const imageLoadingPromises = PresentationData.map((item) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => resolve();
          img.onerror = reject;
          img.src = item.value;
    //      console.log(img.src)
     //     console.log("lets")
        });
      });

      // Wait for all images to load
      await Promise.all(imageLoadingPromises);


      console.log("prese", itemId, PresentationData);
      import ('../assets/css/player.css');
//     import ('reveal.js/dist/reset.css');
// import ('reveal.js/dist/reveal.css');
// import ('reveal.js/dist/theme/black.css');
let wHeight
if (navigator.userAgent.indexOf("Firefox") !== -1) {
  // For Firefox
  
  wHeight = window.outerHeight;
} else {
  // For other browsers like Chrome
  wHeight = window.outerHeight;
  //wHeight = window.innerHeight;
}
    Reveal.initialize({ 
    //    minScale: 0.5, // or any other value
    // maxScale: 15 ,
    // scale:"(0.88)",
    // scale:0.88
    width:  window.innerWidth,
    margin:0,
    padding:0,
    height:wHeight, //new
      alignItems:"centre",  
   // height:  window.innerHeight,
    controls: false ,
   keyboard: {
    27: null // Disable ESC key (27 is the key code for ESC)
  },
  transition: 'slide',
  touch: true,
  minScale: 0.2,//added for responsive
  maxScale: 1.5, //added for responsive
  });

  
  

  // Function called when the API is ready

  
  function playYouTubeVideo(slide) {
    const iframe = slide.querySelector('.draggableVideo iframe');
  
    if (iframe) {
      const src = iframe.src;
  
      // Extract the base URL without autoplay or mute
      const baseUrl = src.split('?')[0];
  
      // Reload the iframe with autoplay and mute enabled
      iframe.src = '';  // Clear src to trigger a reload
      iframe.src = `${baseUrl}?autoplay=1`;
    }
  }
  
  function pauseYouTubeVideo(slide) {
    const iframe = slide.querySelector('.draggableVideo iframe');
  
    if (iframe) {
      const src = iframe.src;
  
      // Extract the base URL without autoplay or mute
      const baseUrl = src.split('?')[0];
  
      // Reload the iframe with autoplay and mute enabled
      iframe.src = '';  // Clear src to trigger a reload
      iframe.src = `${baseUrl}`;
    }
  }

  console.log(window.innerWidth, window.innerHeight)
    Reveal.addEventListener('slidechanged', (event) => {
      hasReached100Percent.current = false;
      //const video = document.querySelector('.video-overlay1');
      // if (video) {
      //   // if (/* your condition */) {
      //     video.currentTime = 0; // Set the current time to 0 to start from the beginning
      //     video.play(); // Start playing the video
      //   } else {
      //     //video.pause(); // Pause the video if the condition is not met
      //   }
   
      const currentSlide = event.currentSlide;
      const previousSlide = event.previousSlide;
      
            // Pause video on the previous slide
    if (previousSlide) {
      const previousVideo = previousSlide.querySelector('.video-overlay1');
      if (previousVideo) {
          previousVideo.pause();
      }
  }

  // Play video on the current slide
  const currentVideo = currentSlide.querySelector('.video-overlay1');
  if (currentVideo) {
      currentVideo.currentTime = 0; // Set the current time to 0 to start from the beginning
      currentVideo.play(); // Start playing the video
  }

 
      slideId = currentSlide;
      console.log("SlideChangedddd")
      ////////////////////////Trackdata/////////////
      const currentIndex = Reveal.getIndices(currentSlide).h;
      setSlideNum(currentIndex+1)
      if (currentIndex !== currentSlideIndexRef.current && firstTtimePlay === false) {
        currentSlideIndexRef.current = currentIndex;
  //      addTrackData({ en:isSharedPrsenation?'sv_1':'esv_1', ev:1,  it: "slideid", iv:currentSlide.getAttribute('data-slide-id'), ot:"pid", ov:itemId}); // Call addTrackData on slide change
          addTrackData({ en:isSharedPrsenation?constants.T_P_SV:constants.T_E_P_SV, ev:1,  sid:currentSlide.getAttribute('data-slide-id'), did:itemId}); // Call addTrackData on slide change
        const currentTime = Date.now();
        const timeSpent = Math.round((currentTime - slideStartTime.current) / 1000);
// time spent in seconds
        slideStartTime.current = currentTime;
          
//        addTrackData({en: isSharedPrsenation?'ts':'ets', ev: timeSpent, it: "slideid", iv: previousSlide.getAttribute('data-slide-id'), ot:"pid", ov:itemId });
          console.log("timeSpent",timeSpent)
          addTrackData({en:isSharedPrsenation?constants.T_P_TS:constants.T_E_P_TS, ev: timeSpent,  sid: previousSlide.getAttribute('data-slide-id'), did:itemId });
       
      }

      ////////////////////////Trackdata/////////////
    //   // Access the current slide element and perform actions
    //   addTrackData({
    //     en: 'sv1',
    //     ev:1
    // });
      timeouts.forEach(timeoutId => clearTimeout(timeoutId));
      timeouts = [];
      // Pause audio on the previous slide
      
      if (previousSlide) {
        const previousAudio = previousSlide.querySelector('audio');
        if (previousAudio) {
          previousAudio.pause();
          previousAudio.loggedProgress = {
            25: false,
            50: false,
            75: false,
            100: false
        };
        }
        pauseYouTubeVideo(previousSlide)
      }

      // Play audio on the current slide
      const currentAudio = currentSlide.querySelector('audio');
      // if (currentAudio) {
      //   currentAudio.play();
      // }
     // console.log("ccccccccccccccuu", currentAudio, currentAudio.src, "abc")
      isManualNavigation = false; // 
      if (isAutoPlay === true && !currentAudio) {
        console.log("bbbbbbbbbbbbbbbb")
        clearTimeout(moveToNextSlideTimeout);
        moveToNextSlideTimeout = setTimeout(() => {
          if (!isManualNavigation) {
            console.log('Moving to next slide after 5 seconds');
            moveToNextSlide();
          }  
        }, 5000);
    }
      if (currentAudio && currentAudio.src ) {
        currentAudio.currentTime = 0;
        setShowPlayIcon(false)
        const timeoutId = setTimeout(() => {
        console.log("Audio Play")
          currentAudio.play().catch((error) => {
            console.error('Error playing audio:', error);
          });
        }, 1000); 
        timeouts.push(timeoutId);
      }
      playYouTubeVideo(currentSlide);
      //var imageContainer = currentSlide.querySelector('.image-container');
      var videoElement = currentSlide.querySelector('.video-overlay');

    });
    Reveal.addEventListener('ready', function (event) {
      if (firstTtimePlay && isSharedPrsenation) {
        disableArrowNavigation();
      } else {
        enableArrowNavigation();
      }
      const currentSlide = event.currentSlide;
      slideId = currentSlide;
      console.log("firrr")
  //    Reveal.next(); 
  //    Reveal.prev(); 
  

  
  // var initialScale = 0.8869142857142858; // Adjust this value as needed
  // event.target.style.zoom = initialScale;
       Reveal.layout();
       setTimeout(() => {
          Reveal.layout();
      }, 1000);

      const video = currentSlide.querySelector('.video-overlay1');
      if (video) {
          video.currentTime = 0;
          video.play().catch((error) => {
              console.error('Error playing video:', error);
          });
      }
      // window.dataLayer.push({
      //   event: 'slideChanged', // Define your custom event name
      //   currentSlideIndex: currentSlide.getAttribute('data-slide-index'), // Add any custom data you want to track
      //   presentationId: itemId,
      //   currentSlideId:currentSlide.getAttribute('data-slide-id')
      // });

      const currentAudio = currentSlide.querySelector('audio');
      
      
      if(currentAudio) {
      currentAudio.loggedProgress = {
        25: false,
        50: false,
        75: false,
        100: false
    };
  }
  isManualNavigation = false; // 
  if (isAutoPlay === true && !currentAudio) {
    console.log("bbbbbbbbbbbbbbbb")
    clearTimeout(moveToNextSlideTimeout);
    moveToNextSlideTimeout = setTimeout(() => {
      if (!isManualNavigation) {
        console.log('Moving to next slide after 5 seconds');
        moveToNextSlide();
      }  
    }, 5000);
}
 
      const currentIndex = Reveal.getIndices(currentSlide).h;
      setSlideNum(currentIndex+1)
      if (currentIndex !== currentSlideIndexRef.current && firstTtimePlay === false) {
        currentSlideIndexRef.current = currentIndex;
//        addTrackData({ en:isSharedPrsenation?'sv_s':'esv_s', ev:1, it: "slideid", iv:currentSlide.getAttribute('data-slide-id'), ot:"pid", ov:itemId }); // Call addTrackData on slide change
        addTrackData({ en:isSharedPrsenation?constants.T_P_SV:constants.T_E_P_SV, ev:1,  sid:currentSlide.getAttribute('data-slide-id'), did:itemId}); // Call addTrackData on slide change

        //addTrackData({ en:isSharedPrsenation?'sv_1':'esv_1', ev:1, it: "slideid", iv:currentSlide.getAttribute('data-slide-id'), ot:"pid", ov:itemId }); // Call addTrackData on slide change
        addTrackData({ en:isSharedPrsenation?constants.T_P_FSV:constants.T_E_P_FSV, ev:1, sid:currentSlide.getAttribute('data-slide-id'), did:itemId }); // Call addTrackData on slide change
      }
    
      if (currentAudio && currentAudio.src &&  firstTtimePlay === false ) {
        const timeoutId =  setTimeout(() => {
        console.log("Audio Play")
        setShowPlayIcon(false)
          currentAudio.play().catch((error) => {
            console.error('Error playing audio:', error);
          });
        }, 1000); 
        timeouts.push(timeoutId);
      }
      
  //      setTimeout(() => {
  //        window.dispatchEvent(new Event('resize'));
  //      }, 2000);

     
      
    //  slidesElement.style.top = "50%";
    });
    

    }
  };
 
    // Disable navigation on initialization if firstTimePlay is true
  
    
    if (!isSharedPrsenation || (isSharedPrsenation && isUserPlanExists)) {
      initializeReveal();
    }
  }, [PresentationData, showAlert, isAutoPlay,firstTtimePlay,isUserPlanExists]);

  if (!PresentationData) {
    return null;
  }
  
  const checkFullScreen = (checkFullScreen, isAutoPlayVar) => {
    if(checkFullScreen) {
      setIsFullScreen(true)
      setIsAutoPlay(isAutoPlayVar)
    // Handle click on the additional div here
    console.log('yess fullscr', isAutoPlayVar, isAutoPlay);
    // timeouts.forEach(timeoutId => clearTimeout(timeoutId));
    // timeouts = [];
    // // if (currSlideAud && currSlideAud.src ) {
    //   const timeoutId =  setTimeout(() => {
    //   console.log("Audio Play")
    //       currSlideAud.play().catch((error) => {
    //       console.error('Error playing audio:', error);
    //     });
    //   }, 1000); 
    //   timeouts.push(timeoutId);
    //   setShowPlayIcon(truee)
    // }
    // Perform actions or trigger functions as needed
    } else {
      setIsFullScreen(false)
      console.log('nooo full sc');
    }
  };
  
  const handleBackClick = () => {
    // Call onBack function
   // onBack();
  
    // Reload the page
   //window.location.reload();
    const existingParams = new URLSearchParams(window.location.search);
console.log("Pres",itemId)
    // Add or update the parameter you want
    existingParams.set('reloadDeckContentItem', itemId);
    existingParams.set('prevIndexNo', prevIndexNo);
    existingParams.set('selectedTitle', selectedTitle);
    existingParams.set('deckId', deckId);
    
    
  
    // Get the updated search string
    const updatedSearchString = existingParams.toString();
  
    // Reload the page with the updated search string
    window.location.href = `${window.location.pathname}?${updatedSearchString}`;
  };
  

  const togglePlay = (e) => {
    const parentContainer = e.currentTarget.closest('.loaderMBgWhite');
  if (parentContainer) {
    const audio = parentContainer.querySelector('audio');
    if (audio) {
      // You can now control the audio element, e.g., toggle play/pause
      if (audio.paused) {
        audio.currentTime = 0;
        audio.play();
        setShowPlayIcon(false)
        isPaused = true;
        
      } else {
        audio.pause();
        setShowPlayIcon(true)
        isPaused = false;
      }
    }
  }

  }

  
  const togglePlayNoAudio = () => {
    if (showPlayIcon) {
      console.log("Resuming slide movement (no audio).");
      isPaused = false;
  
      // Resume the 5-second timer to auto-move to the next slide
      moveToNextSlideTimeout = setTimeout(() => {
        if (!isManualNavigation) {
          console.log('Moving to next slide after 5 seconds');
          moveToNextSlide();
        }
      }, 5000);
    
      setShowPlayIcon(false); // Hide play icon when playing
    } else {
      console.log("Pausing slide movement (no audio).");
      isPaused = true;
      clearTimeout(moveToNextSlideTimeout);

      // Pause the 5-second timer
      
      setShowPlayIcon(true); // Show play icon when paused
    }
  };
  

  
  const collectEmail = (email = '') => {
    if(email) {
    setUserEmail(email);
    setShowAlert(false);
    setFirstTtimePlay(false)
    // Store email in local storage with expiration (for example, 1 day)
    localStorage.setItem('userPresEmail', email);
    localStorage.setItem('emailExpiry', Date.now() + 24 * 60 * 60 * 1000*30); // Expiry set to 1 day
    //addTrackData({ en: 'pe', ev:email,  ot:"pid", ov:itemId}); 
    const currentTime = Date.now();
    // time spent in seconds
    slideStartTime.current = currentTime;
    addTrackData({ en: constants.T_P_FEM, ev:email,  did:itemId}); 
    } else {
      setFirstTtimePlay(false)
    }
    // You can now proceed with further actions using the userEmail state.
    if (presentationHeaderRef.current) {
      presentationHeaderRef.current.clickPresentButton();
    }
  };

  
  // const handleTimeUpdate = (e) => {
  //   const audio = e.currentTarget;
  //   console.log("slideid",   e.currentSlide, slideId)
  //   const progress = Math.round((audio.currentTime / audio.duration) * 100);
    
  //   console.log("audiooos",(audio.currentTime / audio.duration) * 100, audio, hasReached100Percent.current)
  //   if (progress === 25  ) {
  //     console.log('Reached 25%');
  //     addTrackData({ en: 'ap', ev:25, sev:audio.currentTime, it: "slideid", iv:slideId.getAttribute('data-slide-id'), ot:"pid", ov:itemId }); 
  //   }

  //   if (progress === 50 ) {
  //     console.log('Reached 50%');
  //     addTrackData({ en: 'ap', ev:50, sev:audio.currentTime,  it: "slideid", iv:slideId.getAttribute('data-slide-id'), ot:"pid", ov:itemId}); 
  //   }

  //   if (progress === 75 ) {
  //     console.log('Reached 75%');
  //     addTrackData({ en: 'ap', ev:75, sev:audio.currentTime,  it: "slideid", iv:slideId.getAttribute('data-slide-id'), ot:"pid", ov:itemId}); 
  //   }

  //   if (progress === 100  ) {
  //     console.log('Reached 100% progress condition met');
      
  //     addTrackData({ en: 'ap', ev:100, sev:audio.currentTime,  it: "slideid", iv:slideId.getAttribute('data-slide-id'), ot:"pid", ov:itemId}); 
  //   //  moveToNextSlide();
  //   }
  
  // };

  const handleTimeUpdate = (e, presenterId ="") => {
    const audio = e.currentTarget;
    const progress = Math.round((audio.currentTime / audio.duration) * 100);


    // State object to keep track of logged progress points
    if (!audio.loggedProgress) {
        audio.loggedProgress = {
            25: false,
            50: false,
            75: false,
            100: false
        };
    }

      // Debounced actions (non-critical logging)
  if (debounceTimer.current) {
    clearTimeout(debounceTimer.current);
  }

     // Generate or update slideSessionId
     if (!audio.slideSessionId || audio.currentTime === 0) {
      // Generate a new slideSessionId when audio starts from the beginning
      audio.slideSessionId = `${slideId.getAttribute('data-slide-id')}-${Date.now()}`;
      // Reset logged progress for the new session
      audio.loggedProgress = {
          25: false,
          50: false,
          75: false,
          100: false,
      };
  }

  
    if (progress >= 25 && !audio.loggedProgress[25]) {
 //       console.log('Reached 25%');
        // addTrackData({
        //     en: 'ap',
        //     ev: 25,
        //     sev: audio.currentTime,
        //     it: "slideid",
        //     iv: slideId.getAttribute('data-slide-id'),
        //     ot: "pid",
        //     ov: itemId
        // });
        addTrackData({
          en: isSharedPrsenation?constants.T_P_AP:constants.T_E_P_AP,
          ev: 25,
          sev: audio.currentTime,
          sid:slideId.getAttribute('data-slide-id'),
          did:itemId,
          presenterId:presenterId,
          slideSessionId: audio.slideSessionId, // Unique per slide session
      });
        audio.loggedProgress[25] = true;
    }

    if (progress >= 50 && !audio.loggedProgress[50]) {
  //      console.log('Reached 50%');
        addTrackData({
            en: isSharedPrsenation?constants.T_P_AP:constants.T_E_P_AP,
            ev: 50,
            sev: audio.currentTime,
            sid:slideId.getAttribute('data-slide-id'),
            did:itemId,
            presenterId:presenterId,
            slideSessionId: audio.slideSessionId, // Unique per slide session
        });
        audio.loggedProgress[50] = true;
    }

    if (progress >= 75 && !audio.loggedProgress[75]) {
  //      console.log('Reached 75%');
        addTrackData({
          en: isSharedPrsenation?constants.T_P_AP:constants.T_E_P_AP,
          ev: 75,
          sev: audio.currentTime,
          sid:slideId.getAttribute('data-slide-id'),
          did:itemId,
          presenterId:presenterId,
          slideSessionId: audio.slideSessionId, // Unique per slide session
        });
        audio.loggedProgress[75] = true;
    }

    debounceTimer.current = setTimeout(() => {
      console.log(`Debounced audio progress: ${progress}%`);
    if (progress >= 100 && !audio.loggedProgress[100]) {
   //   setShowPlayIcon(true)
 //       console.log('Reached 100%');
        addTrackData({
          en: isSharedPrsenation?constants.T_P_AP:constants.T_E_P_AP,
          ev: 100,
          sev: audio.currentTime,
          sid:slideId.getAttribute('data-slide-id'),
          did:itemId,
          presenterId:presenterId,
          slideSessionId: audio.slideSessionId, // Unique per slide session
        });
        audio.loggedProgress[100] = true;

        // Optionally, you can trigger the move to the next slide
        if(isAutoPlay === true) {
          moveToNextSlide();
          
        }
    }
  
    // Additional non-critical debounced logic here
  }, 300); // Reduced debounce time for smoother event updates
};

const moveToNextSlide = () => {
  const currentIndices = Reveal.getIndices();
  const totalHorizontalSlides = Reveal.getTotalSlides();

  if (currentIndices.h < totalHorizontalSlides - 1) {
    Reveal.next(); // Move to the next slide
  } else {
    console.log('Reached the last slide, do nothing');
  }
};

const moveToPreviousSlide = () => {
  const currentIndices = Reveal.getIndices();

  if (currentIndices.h > 0) {
    Reveal.prev(); // Move to the previous slide
  } else {
    console.log('Reached the first slide, do nothing');
  }
};


  // const moveToNextSlide = () => {
  //   const currentIndices = Reveal.getIndices();
  //   const currentHorizontalIndex = currentIndices.h;
  //   const totalHorizontalSlides = Reveal.getTotalSlides();

  //   if (currentHorizontalIndex < totalHorizontalSlides - 1) {
  //     // Simulate a right arrow key press event
  //     const rightArrowEvent = new KeyboardEvent('keydown', {
  //       key: 'ArrowRight',
  //       keyCode: 39,
  //       which: 39,
  //       bubbles: true,
  //       cancelable: true,
  //     });
  //     document.dispatchEvent(rightArrowEvent);
  //   } else {
  //     console.log('Reached the last slide, do nothing');
  //   }
  // };

  // const moveToPreviousSlide = () => {
  //   const currentIndices = Reveal.getIndices();
  //   const currentHorizontalIndex = currentIndices.h;
  
  //   if (currentHorizontalIndex > 0) {
  //     // Simulate a left arrow key press event
  //     const leftArrowEvent = new KeyboardEvent('keydown', {
  //       key: 'ArrowLeft',
  //       keyCode: 37,
  //       which: 37,
  //       bubbles: true,
  //       cancelable: true,
  //     });
  //     document.dispatchEvent(leftArrowEvent);
  //   } else {
  //     console.log('Reached the first slide, do nothing');
  //   }
  // };
  
  
  document.addEventListener('keydown', (event) => {
    if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
        console.log("Manual navigation detected");
        isManualNavigation = true; // Set flag to true for manual navigation
        clearTimeout(moveToNextSlideTimeout); // Stop autoplay when user navigates manually
    }
});

  const setLoaderDuration = (e) => {
    const audioDuration = e.duration; // Get audio duration in seconds
    
    // Set the animation duration to match the audio duration
    console.log("duuuurat", audioDuration)
  //  document.getElementById('leftLoader').style.animationDuration = `${audioDuration}s`;
  //  document.getElementById('rightLoader').style.animationDuration = `${audioDuration}s`;
  }



  return (
     <>
       {(isSharedPrsenation && firstTtimePlay) && <Modal editAction={"showAlert"} collectEmail={collectEmail} userDetail={userDetail} showAlert={showAlert}/>}
      {/* {isSharedPrsenation && <PresenatationHeader />} */}


      {isSharedPrsenation && (
        <div
          style={{
            opacity: isVisible ? 1 : 0,
            //transition: 'opacity 0.5s ease',
          }}
        >
          <PresenatationHeader checkFullScreen={checkFullScreen} ref={presentationHeaderRef} userDetail={userDetail}/>
        </div>
      )}
      {!isSharedPrsenation && (
        <a
          href="javascript:void(0);"
          onClick={handleBackClick}
          className="additional-div"
          style={{
            zIndex: 9999,
            display: 'flex',
            alignItems: 'center',
//            justifyContent: 'center',
  //          height: '100%',
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M15 18L9 12L15 6"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </a>
      )}
    
<div className="slides">
  {PresentationData.map((item, index) => (
    <section className="image-container" key={index} data-slide-id={item.id} data-slide-index={index} > 
      {/* <div style={{ position: 'relative', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' , transform: 'scale(0.8869142857142858)'}}> */}
      <div style={{ position: 'relative', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img
          className="image-tag"
          src={item.value}
          alt={item.id}
          style={{ width: '100%', height: '100%', objectFit: 'cover' , maxWidth:"100%", maxHeight:"100%", margin:"0"}}
        />

        {item.mediaDetail.map((media, mediaIndex) => (
          <React.Fragment key={mediaIndex}>
            {(() => {
              let locationSizeData;
              let posX = "0%";
              let posY = "0%";
              let sizW = "100%";
              let sizH = "100%";

              if (media.coordinateLocation) {
                locationSizeData = JSON.parse(media.coordinateLocation);
                console.log("locationSizeData", locationSizeData.position.x, locationSizeData.position.y, locationSizeData.size.width, locationSizeData.size.height);

                posX = locationSizeData.position.percentX ? `${locationSizeData.position.percentX}%` : "0%";
                posY = locationSizeData.position.percentY ? `${locationSizeData.position.percentY}%` : "0%";
                sizW = locationSizeData.size.percentWidth ? `${locationSizeData.size.percentWidth}%` : "100%";
                sizH = locationSizeData.size.percentHeight ? `${locationSizeData.size.percentHeight}%` : "100%";
              }

              if (media.source === 1) {
                const videoIdMatch = media.url.match(/[?&]v=([^&]+)/) || media.url.match(/(?:youtu\.be\/|\/embed\/|\/v\/|\/\?v=|&v=)([^&?]+)/);
                const videoId = videoIdMatch ? videoIdMatch[1] : null;
                  
                const finalUrl = `https://www.youtube.com/embed/${videoId}`;

           //     const updatedUrl = media.url.replace("watch?v=", "embed/");
                // Check if the protocol is "http", and if so, replace it with "https"
                // const finalUrl = updatedUrl.startsWith("http://")
                //   ? updatedUrl.replace("http://", "https://")
                //   : updatedUrl;

                return (
                  <div className="draggableVideo" style={{ position: "absolute", width: sizW, height: sizH, left: posX, top: posY }}>
                    <iframe
                      width="100%"
                      height="100%"
                      src={`${finalUrl}`}
                      allow="autoplay"
                    ></iframe>
                  </div>
                );
              }               
              if (media.source === 4) {
                //const updatedUrl = media.url.replace("watch?v=", "embed/");
                // Check if the protocol is "http", and if so, replace it with "https"
                const finalUrl =  media.url.startsWith("http://")
                  ?  media.url.replace("http://", "https://")
                  :  media.url;

                return (
                  <div className="draggableVideo" style={{ position: "absolute", width: sizW, height: sizH, left: posX, top: posY }}>
                    <iframe
                      width="100%"
                      height="100%"
                      src={`${finalUrl}?autoplay=1`}
                      allow="autoplay"
                    ></iframe>
                  </div>
                );
              } else if (media.source === 2) {
                return (
                  <video
                   // controls
                    // {...(!showAlert ? { autoPlay: true } : {})}
                    className="video-overlay1"
                    style={{
                      position: 'absolute',
                      top: posY,
                      left: posX,
                      width: sizW,
                      height: sizH,
                    }}
                  >
                    <source src={media.url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                );
              } else if (media.source === 3) {
                return (
                  <img
                    src={media.url}
                    className="video-overlay"
                    style={{
                      position: 'absolute',
                      top: posY,
                      left: posX,
                      width: sizW,
                      height: sizH,
                      margin:"0",
                      maxWidth:"100%"
                    }}
                  />
                );
              } else {
                return null;
              }
            })()}
          </React.Fragment>
        ))}

      </div>
      {/* {isSharedPrsenation ? (
        <audio controls  style={{ display: item.audioUrl === '' ? 'none' : '', position:'relative',bottom:"150px" }}>
          <source src={item.audioUrl} type="audio/mp3" />
          Your browser does not support the audio tag.
        </audio>
      ) : (
        <audio controls data-autoplay style={{ display: item.audioUrl === '' ? 'none' : '', position:'relative',bottom:"150px"  }}>
          <source src={item.audioUrl} type="audio/mp3" />
          Your browser does not support the audio tag.
        </audio>
      )} */}


  <>

  




<div class="loaderMBgWhite" style={{ display: isPillVisible ? 'flex' : 'none',minWidth:"200px",/*display: item.audioUrl === '' ? 'none' : '', */ position:'absolute', bottom: "50px",  right: isFullScreen?"15px":"15px"}}>
{item.audioUrl !== ""? <audio src={item.audioUrl} type="audio/mp3"   onLoadedMetadata={(e) => setLoaderDuration(e)} ref={audioRef} onTimeUpdate={(e) => handleTimeUpdate(e, item.presenterId?item.presenterId:"")}></audio>:""}
{item.audioUrl !== "" ? (
  <>
   { <div class="audioPlayLoadding"   onClick={(e) => togglePlay(e)}  style={{display: showPlayIcon ? "block" : "none" }}>
      <div class="box cPointer">
         <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.7708 5.54332C12.4653 5.93147 12.4653 6.90185 11.7708 7.29L2.39581 12.53C1.70137 12.9182 0.833313 12.433 0.833313 11.6567L0.833313 1.17661C0.833313 0.400305 1.70137 -0.0848842 2.39581 0.303267L11.7708 5.54332Z" fill="#D9D9D9"/>
         </svg>
      </div>
   </div> }
    
   <div class="audioPlayLoadding"  style={{ display: (showPlayIcon) ? "none" : "flex" }}  onClick={(e) => togglePlay(e)} >
      <div class="box cPointer">
         <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="6" height="16" rx="1" fill="#D9D9D9"/>
            <rect x="10" width="6" height="16" rx="1" fill="#D9D9D9"/>
         </svg>
      </div>
   </div>
   
</>
) : (
  <>
   <div class="audioPlayLoadding"   onClick={(e) => togglePlayNoAudio(e)}  style={{display: showPlayIcon ? "flex" : "none" }}>
     <div class="box cPointer">
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path d="M11.7708 5.54332C12.4653 5.93147 12.4653 6.90185 11.7708 7.29L2.39581 12.53C1.70137 12.9182 0.833313 12.433 0.833313 11.6567L0.833313 1.17661C0.833313 0.400305 1.70137 -0.0848842 2.39581 0.303267L11.7708 5.54332Z" fill="#D9D9D9"/>
        </svg>
     </div>
  </div> 
   
  <div class="audioPlayLoadding"  style={{ display: (showPlayIcon) ? "none" : "flex" }}  onClick={(e) => togglePlayNoAudio(e)} >
     <div class="box cPointer">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
           <rect width="6" height="16" rx="1" fill="#D9D9D9"/>
           <rect x="10" width="6" height="16" rx="1" fill="#D9D9D9"/>
        </svg>
     </div>
  </div>
  
</>
)}

   <div class="pNav">
      <a onClick={moveToPreviousSlide} class="cPointer">
         <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 18L9 12L15 6" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
         </svg>
      </a>
      <span>{slideNum}</span>
      <a onClick={moveToNextSlide} class="cPointer">
         <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 18L15 12L9 6" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
         </svg>
      </a>
   </div>
   {/* <button class="btn Secondary">Edit</button> */}
</div>
</>

    </section>
  ))}
</div>

      </>    
  );

};

export default Presentation;
