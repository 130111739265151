import React, { useState, useEffect } from "react";
import DropFile from "./DropFile";
const PresenterModalContent = ({ onFileUpload, onComplete, closeModal, activeNavItem }) => {
  const handleCloseModal = () => {
    closeModal(); // Close the modal using the function passed as prop
  };

  const [textInput, setTextInput] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  const handleTextInputChange = (event) => {
    setTextInput(event.target.value);
    setErrorMessage('');
  };
  const handleDropFileError = (error) => {
    setErrorMessage(error);
  };

  return (
 
            <div class="model_box_child addNewPresPop" id="addNewPresPop"   style={{ width: '752px'}}>
         <h4>
         <span class="icon">
               <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                 <path d="M29.3334 28V25.3333C29.3334 22.8482 27.6337 20.7601 25.3334 20.168M20.6667 4.38768C22.6213 5.17886 24.0001 7.09508 24.0001 9.33333C24.0001 11.5716 22.6213 13.4878 20.6667 14.279M22.6667 28C22.6667 25.515 22.6667 24.2725 22.2608 23.2924C21.7195 21.9855 20.6812 20.9473 19.3744 20.406C18.3943 20 17.1518 20 14.6667 20H10.6667C8.18173 20 6.93922 20 5.9591 20.406C4.65229 20.9473 3.61402 21.9855 3.07272 23.2924C2.66675 24.2725 2.66675 25.515 2.66675 28M18.0001 9.33333C18.0001 12.2789 15.6123 14.6667 12.6667 14.6667C9.72123 14.6667 7.33341 12.2789 7.33341 9.33333C7.33341 6.38781 9.72123 4 12.6667 4C15.6123 4 18.0001 6.38781 18.0001 9.33333Z" stroke="#181110" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
               </svg>
            </span>
            <span>Add new presenter</span>
            <span class="close_model" onClick={handleCloseModal} >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M18 6L6 18M6 6L18 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
               </svg>
            </span>
         </h4>

         <div class="contentSection">
         <div class="addForm mb-12">
            <label>Presenter’s Name</label>
            <input type="text" name="" id="" placeholder="Enter a name" class="mr-8 w100" style={{border: "1px solid white"}}  value={textInput} onChange={handleTextInputChange}/>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
         </div>
         <DropFile
        onFileUpload={onFileUpload}
        onComplete={onComplete}
        closeModal={closeModal}
        activeNavItem={activeNavItem}
        textInput={textInput}
        onError={handleDropFileError}
      />

         </div>
      </div>
    
  );
};


export default PresenterModalContent;
