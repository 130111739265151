import React, { useState, useEffect } from 'react';
import { apiUrls } from "../utils/apiUrls";
import { callAPI } from "../utils/apiUtils";
import { formatDateAsTh } from "../utils/utilFn";

const PaymentSetting = ({setPlanforPayment}) => {

  const [plans, setPlans] = useState([]);
  const [userPlan, setUserPlan] = useState(false);
  const [displayPlan, setDisplayPlan] = useState("month");
  const [userFuturePlan, setUserFuturePlan] = useState({});

    const handleUpgradeClick = async (planId) => {
      setPlanforPayment(planId)
    };
    useEffect(() => {
      const fetchData = async () => {
        try {
          const userId=localStorage.getItem("userId")
          const response = await callAPI(apiUrls.GETPAYMENTPLANS, {}, "GET");

          console.log(response);
          setPlans(response.data.plans); 
          const userResponse = await callAPI(apiUrls.GETUSERPLAN, {userId:userId}, "GET");
          console.log(userResponse.data.plan);
          if(userResponse.data.plan) {
            setUserPlan(userResponse.data.plan)
          }
          if(userResponse.data.futrePlan) {
            setUserFuturePlan(userResponse.data.futrePlan)
          } else {
            setUserFuturePlan(false)
          }

        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData(); // Call the fetchData function when the component mounts
    }, []);     
 
    return (
        <div class="dashContent deckDashboard ">
          <div class="alignCenter" style={{width:"100%", textAlign:"center"}}><h1 class="alignCenter"  style={{ textAlign:"center", display:"block"}}>Pricing Plans</h1></div>
          <div class="alignCenter" style={{width:"100%", textAlign:"center"}}><h3 class="alignCenter"  style={{ textAlign:"center", display:"block", margin:"20px"}}>Simple, transparent pricing that grows with you. Free trial for first 14 days.</h3></div>
          <div class="alignCenter" style={{ width:"100%", textAlign:"center"}}> 
          <div class="alignCenter btn PrimaryOutline" style={{ textAlign:"center", padding:"5px 5px"}}> 
          <button
  className={`btn ${displayPlan === "month" ? "PrimaryOutline" : ""}`}
  style={
    displayPlan === "month"
      ? { background: "rgb(217, 217, 217)", color: "black" }
      : { color: "white" }
  }
  onClick={() => setDisplayPlan("month")}
>
  Monthly billing
</button>
<button
  className={`btn ${displayPlan === "year" ? "PrimaryOutline" : ""}`}
  style={
    displayPlan === "year"
      ? { background: "rgb(217, 217, 217)", color: "black" }
      : { color: "white" }
  }
  onClick={() => setDisplayPlan("year")}
>
  Annual billing
</button>
          </div>    
          <div style={{color:"#00BFA5"}}>You get 2 months free by paying annually</div>
          </div>   
          <div class="dForm deckListing mt-24">
            <div class="rowList flexWrap">
          
                  {plans.map((plan, index) => {
                  const description = JSON.parse( plan.description);
                  const isShow = displayPlan === "year" 
                ? description.duration.includes("year") 
                : displayPlan === "month" 
                  ? description.duration.includes("month") 
                  : false;
                  return (
                    <>
                    {isShow && (
                    <div class="itemWrap dbBox " style={{minHeight:"600px", width:"30%", margin:"10px", padding:"0px", justifyContent:"unset", backgroundColor: userPlan && userPlan.planId === plan.pid ? "rgb(217, 217, 217)" : "" , color: userPlan && userPlan.planId === plan.pid ? "black" : "" }}>
            <div class="item row jcSpacebetween gap24">
                       
                         <div class="col mrAuto main_navigation" style={{borderRight:"none"}}>
                          <span style={{marginBottom:"100px"}}><h3>{plan.title}</h3></span>
                           <span> <h2>{description.priceLabel}</h2></span>
                           {userFuturePlan.planId === plan.pid ? (
                         <button className="btn PrimaryOutline" style={{ background:"gray", color:"black"}} >
                            You will be moved on {formatDateAsTh(userFuturePlan.planStart)}
                        </button>
                        ) : userPlan.planId !== plan.pid ? (
                          <button
                            className="btn PrimaryOutline"
                            style={{ background: "rgb(217, 217, 217)", color: "black" }}
                            onClick={() => {handleUpgradeClick(plan.pid); }}
                          >
                            Select Plan
                          </button>
                      ): (
                          <button className="btn PrimaryOutline" style={{ background:"#000000", color:"rgb(217, 217, 217)"}}  >
                            Keep Plan
                        </button>
                        )}
                         
                           <span style={{borderBottom:"2px solid #424242"}}>&nbsp;</span>
                           <span  style={{marginTop:"20px",  color: userPlan && userPlan.planId === plan.pid ? "#000000" : "#00BFA5"}}> {description.summary}</span>
                           <ul class="nav" style={{marginTop:"20px"}}>
                           {description.features.map((feature, featureIndex) => (
                           
                           <li><span><svg fill= {userPlan && userPlan.planId === plan.pid ? "#000000" : "#ffffff"} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" 
                           width="20px" height="20px" viewBox="0 0 305.002 305.002"
                           >
                       <g>
                          <g>
                             <path d="M152.502,0.001C68.412,0.001,0,68.412,0,152.501s68.412,152.5,152.502,152.5c84.089,0,152.5-68.411,152.5-152.5
                                S236.591,0.001,152.502,0.001z M152.502,280.001C82.197,280.001,25,222.806,25,152.501c0-70.304,57.197-127.5,127.502-127.5
                                c70.304,0,127.5,57.196,127.5,127.5C280.002,222.806,222.806,280.001,152.502,280.001z"/>
                             <path d="M218.473,93.97l-90.546,90.547l-41.398-41.398c-4.882-4.881-12.796-4.881-17.678,0c-4.881,4.882-4.881,12.796,0,17.678
                                l50.237,50.237c2.441,2.44,5.64,3.661,8.839,3.661c3.199,0,6.398-1.221,8.839-3.661l99.385-99.385
                                c4.881-4.882,4.881-12.796,0-17.678C231.269,89.089,223.354,89.089,218.473,93.97z"/>
                          </g>
                       </g>
                       </svg></span><span>{feature}</span>
                       </li>
                          ))}
                          </ul>



                         </div>
                         <div class="col" style={{textAlign:"center", width:"100%"}}>
                         {/* <a href="javascript:void(0);" className="btn PrimaryOutline"  onClick={handleUpgradeClick} >Upgrade Plan</a> */}
                      

                         </div>
                      </div>
        
                   </div>
                    )}
                   </>
                  );
})}
                   
              <div class="itemWrap dbBox " style={{width:"30%", margin:"10px", padding:"0px", justifyContent:"unset", minHeight:"600px" }}>
                <div class="item row jcSpacebetween gap24">
                  
                    <div class="col mrAuto main_navigation" style={{borderRight:"none"}}>
                    <span><h3>Deck Enterprise</h3></span>
                      <span  style={{marginBottom:"85px",  color: "#00BFA5"}}> Scale with custom features for individuals or corporates.</span>
                      
                    <button className="btn PrimaryOutline" style={{ background:"rgb(217, 217, 217)", color:"black"}}  >
                      Get in touch
                  </button>
                    </div>
                    <div class="col" style={{textAlign:"center", width:"100%"}}>
                  
                    </div>
                </div>
  
              </div>
        
             </div>
             
          </div>
      </div>

    );
};

export default PaymentSetting;