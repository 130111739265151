export const defaultConfig = {
// baseAPIUrl:"http://ec2-13-233-237-24.ap-south-1.compute.amazonaws.com:5001",
//baseAPIUrl:"http://localhost:5001",
//stripePublishKey:"pk_test_51P82osHjl5nedYUgzDXb1n783GwQswARj2R7fRNe5lvhIc8Q5kTFgbqY3TCUput7Lja4agAeRPxEzd05y7VTdQu200WOLlj0mf",




//staging
//baseAPIUrl:"https://backend.staging.deck.work",
//stripePublishKey:"pk_test_51P82osHjl5nedYUgzDXb1n783GwQswARj2R7fRNe5lvhIc8Q5kTFgbqY3TCUput7Lja4agAeRPxEzd05y7VTdQu200WOLlj0mf",


//production
baseAPIUrl:"https://backend.deck.work",
stripePublishKey:"pk_live_51P82osHjl5nedYUg0KFF9j5vOiKL26sJJTtlE9jmkt0AsFZMJB9blmOAbM6qMc67A6aw4ehXDbv5jA2ql6eGech1009zguXK4C",

}